import { useContext, } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';

import * as strings from '../../data/strings';

import '../../styles/marketing/Features.scss';
const Features = () => {
	const { store } = useContext(Context);
	const history = useHistory();

	const featureAI = [
		{
			emoji: "🪄",
			title: "MagicAIPrompts",
			description: "UnlimitedMagicAIPromptsDescription"
		},
		{
			emoji: "🔮",
			title: "UnlimitedRecording",
			description: "UnlimitedRecordingLimitDescription"
		},
		{
			emoji: "🎤",
			title: "AIVoiceToneAnalysis",
			description: "AIVoiceToneAnalysisDescription"
		},
		{
			emoji: "✍️",
			title: "AIAutoTranscription",
			description: "AIAutoTranscriptionDescription"
		},
		{
			emoji: "🔎",
			title: "AIKeywordsAndInsights",
			description: "AIKeywordsAndInsightsDescription"
		},
		{
			emoji: "🔗",
			title: "DocumentAttachments",
			description: "DocumentAttachmentsDescription"
		}
	]

	const featuresData = [
		{
			title: "Basic",
			price: "BasicPrice",
			details: "BasicDetails",
			description: "StartCreating",
			features: [
				"RecordAnswerPrompts",
				"CreateNumberCommunities",
				"CreateTwentyFiveParticipants",
				"OneMinuteRecordingTime"
			],
			route: "/register/",
			button: "StartNow",
			buttonClass: ""
		},
		{
			title: "Premium",
			price: "PremiumPrice",
			details: "PremiumDetails",
			description: "AttachmentAIMore",
			features: [
				"RecordUnlimitedPrompts",
				"CreateUnlimitedCommunities",
				"InviteUnlimitedParticipants",
				"UnlimitedRecordingTime",
				"AttachDocumentsVideos",
				"AIPoweredFeatures"
			],
			route: "/register/",
			button: "CreateNow",
			buttonClass: "magicAIButton"
		}
	]

	const openEmail = () => {
		window.location.href = 'mailto:hello@usegravity.io';
	}

	return (
		<div className="Features">
			<div className="contentWrapper">
				<div className="creatorBackground" />
				<div className="contentContent">
					<div className="contentBlock">
						<div className="contentTitle">
							{strings.default[store.language].Marketing.AffordablePricing}
						</div>
						<div className="contentDescription">
							{strings.default[store.language].Marketing.AffordablePricingDescription}
						</div>
						<button
							className="contentFeature"
							onClick={() => history.push('/register/')}>
							{strings.default[store.language].Marketing.AffordablePricingCallout}
						</button>
					</div>
				</div>
				<div className="premiumWrapper">
					{featureAI.map((feature, index) => (
						<div className="premium" key={index}>
							<div className="premiumTitle">
								{feature.emoji} <span className="text">{strings.default[store.language].Package[feature.title]}</span>
							</div>
							<div className="premiumDescription">
								{strings.default[store.language].Package[feature.description]}
							</div>
						</div>
					))}
				</div>
				<div className="contentContent">
					<div className="contentBlock">
						<div className="pricingTable">
							{featuresData.map((feature, index) => (
								<div
									className="plan"
									key={index}>
									<h2>
										{strings.default[store.language].Pricing[feature.title]}
									</h2>
									<p className="planPrice">
										{strings.default[store.language].Pricing[feature.price]}
									</p>
									<p className="planDiscount">
										* {strings.default[store.language].Pricing[feature.details]}
									</p>
									<p className="planDesc">
										{strings.default[store.language].Pricing[feature.description]}
									</p>
									<ul className="features">
										{feature.features.map((featureItem, index) => (
											<li key={index}>
												{ReactHtmlParser(strings.default[store.language].Pricing[featureItem])}
											</li>
										))}
									</ul>
									<button
										className={`signupButton${feature.buttonClass.length ? ` ${feature.buttonClass}` : ''}`}
										onClick={() => history.push(feature.route)}>
										{strings.default[store.language].Pricing[feature.button]}
									</button>
								</div>
							))}
						</div>
						<div className="specialPricing">
							<div className="wrapper">
								{strings.default[store.language].Pricing.District}
							</div>
							<div className="buttonWrapper">
								<button 
									className="contactButton"
									onClick={() => openEmail()}>
									{strings.default[store.language].Pricing.ContactPricing}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Features;