import { useContext, useState } from 'react';
import { Context } from '../../DataStore';
import { useHistory, useLocation } from 'react-router-dom';

import ProfileControl from '../common/ProfileControl';
import AssetsView from './AssetsView';
import SpacesView from './SpacesView';
import Loading from '../common/Loading';
import DashboardViewer from '../composite/DashboardViewer';
import CreatorStudio from '../dashboard/CreatorStudio';
import Tutorials from '../dashboard/Tutorials';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/Content.scss';

const GravityLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-logo.svg`;
const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;

const Content = (props) => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();
  const location = useLocation();

  const [spaceId, setSpaceId] = useState('');
  const [localLoad, setLocalLoad] = useState(false);
  const [loadingDeeplink, setLoadingDeeplink] = useState(false);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const goHome = () => {
    if (location.pathname !== '/dashboard/') {
      history.push('/dashboard/');
      window.location.reload();
    }
  }

  const loadSpace = async (space) => {
    setSpaceId(space.sid);
    setLocalLoad(true);
    setLoadingDeeplink(true);
    props.setLoadingDeeplink(true);

    try {
      const data = {
        uuid: store.profile.uuid,
        sid: space.sid,
        sessionId: store.session.sessionId
      }
      const url = `${constants.services.url.api}/asset/fetch/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok) {
        // Add to URL Route
        history.push(`/g/${store.status.community.cid}/${responseData.data.gid}/${space.sid}/`);

        setSpaceId('');
        setLocalLoad(false);
        setLoadingDeeplink(false);
        props.setLoadingDeeplink(false);

        // Add to DataStore
        const storeCopy = {
          ...store,
          assets: {
            data: responseData.data,
            fetched: true
          },
          status: {
            ...store.status,
            space: space
          }
        }
        dispatch({
          type: 'store',
          data: storeCopy
        });
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const backToSpaces = (cacheStore) => {
    // Add to URL Route
    history.push(`/g/${cacheStore.status.community.cid}/${cacheStore.status.group.gid}/`);

    // Clear from DataStore
    const storeCopy = {
      ...cacheStore,
      assets: {
        data: {},
        fetched: true
      },
      status: {
        ...store.status,
        asset: {},
        members: [],
        space: {},
        modal: {
          active: false,
          action: null,
          type: null,
          store: null,
          thread: null,
          data: {
            title: null,
            description: null,
            inputTitle: null,
            placeholder: null,
            privacySetting: false
          }
        }
      }
    }
    dispatch({
      type: 'store',
      data: storeCopy
    });
  }

  const backToDashboard = () => {
    const statusCopy = {
      ...store.status,
      tutorials: {
        active: false,
        backFrom: true,
        content: null
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
    history.push('/dashboard/');
  }

  const backToTutorials = () => {
    const statusCopy = {
      ...store.status,
      tutorials: {
        ...store.status.tutorials,
        content: null
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
    history.push('/t/');
  }

  const removeSpace = async (space, selfDelete, event) => {
    if (store.status.modal.active) {
      try {
        let data = {
          cid: store.status.community.cid,
          gid: store.status.group.gid,
          sid: space.sid,
          uuid: store.profile.uuid,
          spaceUuid: store.status.group.creator === store.profile.uuid ? space.creator.uuid : null,
          sessionId: store.session.sessionId
        }

        const url = `${constants.services.url.api}/space/modify/deactivate/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status === 'Success') {

            let spacesDataCopy = [
              ...store.spaces.data
            ]
            const spaceIndex = spacesDataCopy.map((spaceData) => spaceData.sid).indexOf(space.sid);
            if (spaceIndex > -1) {
              spacesDataCopy.splice(spaceIndex, 1);

              const spacesCopy = {
                ...store.spaces,
                data: spacesDataCopy
              }

              const storeCopy = {
                ...store,
                spaces: spacesCopy,
                status: {
                  ...store.status,
                  modal: {
                    active: false,
                    action: null,
                    type: null,
                    store: null,
                    thread: null,
                    data: {
                      title: null,
                      description: null,
                      inputTitle: null,
                      placeholder: null,
                      privacySetting: false
                    }
                  }
                }
              }

              if (selfDelete) {
                backToSpaces({
                  ...store,
                  spaces: spacesCopy
                })
              } else {
                dispatch({
                  type: 'store',
                  data: storeCopy
                });
              }
            }
          } else {
            sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } else {
      event.persist();
      const statusCopy = {
        ...store.status,
        modal: {
          active: true,
          action: 'delete',
          type: 'confirm',
          store: '',
          thread: null,
          event: event,
          data: {
            title: strings.default[store.language].Menu.ConfirmDeleteDiscussion,
            description: strings.default[store.language].Menu.ConfirmDeleteDescription,
            inputTitle: space.metadata.title,
            placeholder: space.gid,
            privacySetting: true
          }
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const renderLoading = () => {
    const targetSpaceId = (localLoad ? spaceId : store.deeplink.params.spaceId)

    const assetIndex = store.spaces.data.map((space) => space.sid).indexOf(targetSpaceId);
    if (assetIndex > -1) {
      const space = store.spaces.data[assetIndex];
      return (
        <>

          <div className="assetGif">
            <div className="preview">
              {space.type === 'voice' ?
                <div
                  className="previewAuthor"
                  style={{ backgroundImage: `url(${space.creator.image !== undefined && space.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(space.creator.image) : DefaultAvatar})` }} /> :
                <div
                  className="previewVideo"
                  style={{ backgroundImage: `url("${constants.services.awsLocation(true)}/${space.aid}.gif")` }} />
              }
            </div>
          </div>
          <div className="assetTitle">
            {space.metadata.title}
          </div>
          <div className="assetCreator">
            {space.creator.name}
          </div>
        </>
      )
    } else {
      return store.deeplink.params.spaceId;
    }
  }

  const assetSettings = async () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'settings',
        data: {
          title: strings.default[store.language].AssetsView.Settings,
          description: strings.default[store.language].AssetsView.SettingsDescription
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  return (
    <div className="Content">
      <div className={`banner${(constants.utils.isObject(store.status.asset) && !props.closing) || props.loadingDeeplink || loadingDeeplink ? ' blurred' : ''}`}>
        <button
          className="gravityHome"
          onClick={() => goHome()}>
          <img
            className="gravityLogo"
            src={GravityLogo}
            alt="Gravity logo" />
        </button>

        {(!constants.utils.isObject(store.assets.data) && !store.status.tutorials.active) &&
          <div className="announcement">
            <span className="newWrapper">
              <span className="new">
                {strings.default[store.language].Header.New}
              </span>
            </span>
            <span className="text">
              <span className="bold">{strings.default[store.language].Header.BrandNewPricing}</span><span className="emoji">✨</span>{strings.default[store.language].Header.ComingApril}
            </span>
          </div>
        }

        <ProfileControl />

        <div className="typeWrapper">
          {(constants.utils.isObject(store.assets.data) || store.status.tutorials.active) &&
            <div className="assetWrapper">
              <div className="backWrapper">
                <button
                  className="backButton"
                  onClick={() => store.status.tutorials.active ? 
                    store.status.tutorials.content === null ?
                    backToDashboard() : backToTutorials() : backToSpaces(store)}>
                  {`⇽ ${store.status.tutorials.active ? strings.default[store.language].Dashboard[store.status.tutorials.content === null ? 'BackToDashboard' : 'BackToTutorials'] : `${strings.default[store.language].AssetsView.Back} ${store.status.group.title}`}`}
                </button>
              </div>
            </div>
          }
        </div>
      </div>

      {constants.utils.isObject(store.assets.data) ?
        <AssetsView
          closing={props.closing}
          removeSpace={(space, selfDelete, event) => removeSpace(space, selfDelete, event)}
          assetSettings={() => assetSettings()} /> :
        store.spaces.fetched ?
          <SpacesView
            loadingDeeplink={props.loadingDeeplink || loadingDeeplink}
            loadSpace={(space) => loadSpace(space)}
            removeSpace={(space, event) => removeSpace(space, false, event)} /> :
          <div className="content">
            <div className="welcomeContent">
              {store.status.tutorials.active ?
                <Tutorials /> :
                <>
                  <CreatorStudio />
                  <DashboardViewer />
                </>
              }
            </div>
          </div>
      }
      {((props.loadingDeeplink || loadingDeeplink) &&
        (((constants.utils.isObject(store.spaces) &&
          constants.utils.isObject(store.deeplink.params) && (store.deeplink.params.spaceId !== undefined && store.deeplink.params.spaceId.length))) || localLoad)) &&
        <div className="assetLoader">
          <div className="assetBlur" />
          <div className="assetWrapper">
            <div className="assetData">
              {renderLoading()}
              <Loading active={true} />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Content;
